import { useLaunchDarkly } from "~/composables/useLaunchDarkly";
import routes from "~/constants/routes";
import { useNavigationStore } from "@/store/navigation";
import { checkPlan } from "~/utils/PlanFeatureCheck";
import { sidebarOptions } from "~/types/NavigationStateType";

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) return;

  const navigationStore = useNavigationStore();
  const ld = useLaunchDarkly();
  let showNewPPTReportsFlag = false;
  if (!ld.isLDClient()) {
    showNewPPTReportsFlag = await ld.getFlagValue("showNewPPTReports");
  }

  let path = to.fullPath.includes("?")
    ? to.fullPath.substring(0, to.fullPath.indexOf("?"))
    : to.fullPath;

  // Handle edge cases
  if (to.fullPath.includes("graph=articles")) {
    path = `${path}?graph=articles`;
  } else if (to.fullPath.includes("graph=topics")) {
    path = `${path}?graph=topics`;
  } else if (to.fullPath.includes("/ppt-reports") && !showNewPPTReportsFlag) {
    // TODO: Remove this check once we fully migrate to the new PPT Reports
    return navigateTo("/search");
  }

  let flag = false;
  routes.every((route) => {
    return route.subRoutes.every((subRoute) => {
      if (subRoute?.path !== "/" && path === subRoute?.path) {
        flag = true;
        navigationStore.setActiveSideBarOption(route.name as sidebarOptions);
        navigationStore.setActiveNavBarOption(subRoute.id);
        navigationStore.setActiveNavBarOptions(route.subRoutes);
        return false;
      }
      return true;
    });
  });

  // This block handles the edge case where we hit the mention & publication tabs
  if (
    path.includes("manage/added-mentions") ||
    path.includes("manage/excluded-mentions") ||
    path.includes("manage/requested-publications") ||
    path.includes("manage/excluded-publication")
  ) {
    navigationStore.setActiveSideBarOption(routes[4].name as sidebarOptions);
    navigationStore.setActiveNavBarOption(routes[4].subRoutes[2].id);
    navigationStore.setActiveNavBarOptions(routes[4].subRoutes);
  }

  // TODO: Add block to handle alerts sub-pages

  // This block handles the edge case where we hit the search edit page
  // from the monitor tab of the sidebar
  if (path.includes("searches/edit")) {
    navigationStore.setActiveSideBarOption(routes[3].name as sidebarOptions);
    navigationStore.setActiveNavBarOption(routes[3].subRoutes[4].id);
    navigationStore.setActiveNavBarOptions(routes[3].subRoutes);
  }

  // This block handles the edge case where settings page doesn't display sidebars
  if (path.includes("#/settings/user")) {
    navigationStore.setActiveSideBarOption(routes[5].name as sidebarOptions);
    navigationStore.setActiveNavBarOption(routes[5].subRoutes[0].id);
    navigationStore.setActiveNavBarOptions(routes[5].subRoutes);
  }

  // If flag is false, no route was set and we should fall back to defaults
  if (!flag && path === "/pro/#/monitor") {
    navigationStore.setActiveSideBarOption(routes[0].name as sidebarOptions);
    navigationStore.setActiveNavBarOption(1);
    navigationStore.setActiveNavBarOptions(routes[0].subRoutes);
  }

  const activeRoute = navigationStore.activeNavBarOptions;
  activeRoute?.forEach((subRoute) => {
    // Enable nav bar options based on plan features
    const hasPlanFeature = checkPlan(
      localStorage.getItem("organization") ?? "{}",
      subRoute.translationKey,
    );

    if (hasPlanFeature) {
      navigationStore.setDisplay(subRoute.id, true);
    }
  });
});
